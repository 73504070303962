// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:6e137f22-09e7-4644-b137-0eb44a99ad34",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_bweM8nAec",
    "aws_user_pools_web_client_id": "2askeb8f14s0q8eeek0v44aq65",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://bvfjpghudrc5tll35whwacgjye.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "driver-bureau-web--hostingbucket-prod",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d2ukqj0wf046ph.cloudfront.net",
    "aws_user_files_s3_bucket": "driverbureauwebefb4461104364f2fa762b15b248a9709-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
